
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import LayoutService from '@/core/services/LayoutService'
import StatisticsWidget1 from '@/components/mc-admin-widgets/StatisticsWidget1.vue'
import { setCurrentPageTitle, setCurrentPageTitleButton } from '@/core/helpers/breadcrumb'
import { getMedia } from '@/core/mc-admin-helpers/assets'
import {
  getStatusBadge,
  formatListDate,
  formatListHourInterval
} from '@/core/mc-admin-helpers/activity-session'
import { ActivitySessionFilter } from '@/store/modules/ActivitySessionModule'
import { Actions, MutationsActions } from '@/store/enums/StoreEnums'
import { Metrics, MetricsPayload } from '@/store/modules/StatisticModule'
import { ActivityTemplateFilter } from '@/store/modules/TemplateModule'
import { MenuComponent } from '@/assets/ts/components'
// import { Account } from '@/store/modules/AccountModule'

export default defineComponent({
  name: 'console',
  components: {
    StatisticsWidget1
  },
  setup () {
    const store = useStore()
    const sessions = ref(computed(() => store.getters.getActivitySessions))
    const sessionsFinishedCount = computed(() => store.getters.getActivitySessionsFinishedCount)
    const activityTemplatesCount = computed(() => store.getters.getActivityTemplatesCount)
    const currentAccount = ref(computed(() => store.getters.currentAccount))

    const metrics = computed(() => store.getters.getMetrics) as any as Metrics
    const Widget1Button = {
      url: '/learning-paths',
      name: 'Schedule learning path',
      class: 'houston-brand-btn-success houston-btn-bounce',
      icon: 'calendar-check'
    }

    const Widget2Button = {
      url: '/activity-manager',
      name: 'Build activity',
      class: 'houston-brand-btn-purple houston-btn-bounce',
      icon: 'tools'
    }

    const onDownloadAnalytics = (activitySessionUuid) => {
      const filter: MetricsPayload = {
        metrics: ['session_statistics'],
        filter: {
          activitySessionUuid: activitySessionUuid,
          format: 'xlsx'
        }
      }
      store.dispatch(Actions.API_GET_METRICS_BY_FILTER, filter)
    }

    onMounted(() => {
      watch(() => sessions.value, (sessionsUpdated, oldSessions) => {
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading')
      })
      const payload: ActivitySessionFilter = { filter: { limit: 5 } }
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS, payload)
      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_FINISHED_COUNT)
      const filter: MetricsPayload = { metrics: ['finished_learning_paths', 'total_active_users_logged_in'], filter: { companies: [currentAccount.value.company.uuid] } }
      store.dispatch(MutationsActions.API_GET_MUTATE_METRICS_BY_FILTER, filter)
      const payloadCountActivities: ActivityTemplateFilter = { filter: { isPublished: true } }

      store.dispatch(MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES_COUNT, payloadCountActivities).then(() => {
        MenuComponent.reinitialization()
      })
      setCurrentPageTitle('Console')
      setCurrentPageTitleButton({})
    })

    onUnmounted(() => {
      if (!localStorage.getItem('config')) {
        LayoutService.disableSidebar()
      }
    })

    return {
      sessions,
      Widget1Button,
      Widget2Button,
      sessionsFinishedCount,
      activityTemplatesCount,
      metrics,
      currentAccount,
      getMedia,
      getStatusBadge,
      formatListDate,
      formatListHourInterval,
      onDownloadAnalytics
    }
  }
})
